import React from 'react'
import { navigate } from 'gatsby-link'
import NumberFormat from 'react-number-format'
import Select, { components } from 'react-select'

const DropdownIndicator = props => {
  return (
    <components.DropdownIndicator {...props}>
      <svg
        width="20"
        height="20"
        fill="none"
        stroke="currentColor"
        stroke-width="2"
        class="dropdown-heading-dropdown-arrow gray"
      >
        <path d="M6 9L12 15 18 9"></path>
      </svg>
    </components.DropdownIndicator>
  )
}

const interestedOptions = [
  {
    value: 'Detached/Single Dwelling',
    label: 'Detached/Single Dwelling',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Semi-Detached/Townhouse',
    label: 'Semi-Detached/Townhouse',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Condominium',
    label: 'Condominium',
    name: 'interestedProperties',
    type: 'text',
  },
  {
    value: 'Other',
    label: 'Other',
    name: 'interestedProperties',
    type: 'text',
  },
]

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

export default class Index extends React.Component {
  constructor(props) {
    super(props)
    this.state = { isValidated: false }
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute('action')))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <div>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1>Contact</h1>
              <form
                name="contact"
                method="post"
                action="/thanks"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{' '}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'name'}>
                    Your name
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'text'}
                      name={'name'}
                      onChange={this.handleChange}
                      id={'name'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Email
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'email'}
                      name={'email'}
                      onChange={this.handleChange}
                      id={'email'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                    <label className="label" htmlFor={'email'}>
                      Phone Number
                    </label>
                    <NumberFormat
                      name="phone"
                      placeholder={'Enter phone number'}
                      format="(###) ###-####"
                      label="Phone Number"
                      className="contactFormInput"
                      onChange={this.handleChange}
                      type={'phone'}
                      id={'phone'}
                    style={{
                      marginRight: 5,
                      background: '#fff',
                      color: '#000',
                      border: '1px solid lightgray',
                      height: '40px',
                      paddingLeft: '15px',
                    }}
                    />
                  </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    Property Address
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'propertyAddress'}
                      name={'propertyAddress'}
                      onChange={this.handleChange}
                      id={'propertyAddress'}
                      required={true}
                    />
                  </div>
                </div>
                <div className="field">
                  <label className="label" htmlFor={'email'}>
                    City
                  </label>
                  <div className="control">
                    <input
                      className="input"
                      type={'city'}
                      name={'city'}
                      onChange={this.handleChange}
                      id={'city'}
                      required={true}
                    />
                  </div>
                </div>
                 <div className="field">
                  <label className="label" htmlFor={'email'}>
                    City
                  </label>
                  <fieldset>
    <legend>Can you attend?</legend>
    <p>
      <label>
        <input type="radio" name="areYouWithARealtor" value="Attending"/> Yes
      </label>
    </p>
    <p>
      <label>
        <input type="radio" name="areYouWithARealtor" value="Not Attending"/> No
      </label>
    </p>
  </fieldset>
                  <Select
                    options={interestedOptions}
                    name={'interestedProperties'}
                    type={'interestedProperties'}
                    id={'interestedProperties'}
                  placeholder="Type of Properties:"
                  // onChange={handleSelectChange}
                  className="signUpDropDownWidth hundoWidth advantageForm"
                  classNamePrefix="filter"
                  components={{ DropdownIndicator }}
                  style={{ color: '#000' }}
                />
                </div>



                <div className="field">
                  <button className="button is-link" type="submit">
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </section>
      </div>
    )
  }
}